<template>
    <div class="edition-stats">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">
                        Статистика редакции Базы знаний
                    </h3>

                    <button
                        type="button"
                        class="btn-block-option close-modal"
                        @click="$modal.hide('edition-stats')"
                    >
                        ×
                    </button>
                </div>

                <div class="block-content content">
                     <p>
                        В статистику входит количество активных редакторов, сколько статей ими было отредактировано и дата со временем.
                     </p>

                    <vue-date-picker
                        :inline="true"
                        :no-header="true"
                        :range="true"
                        :fullscreen-mobile="true"
                        :max-date="maxDate"
                        v-model="date"
                    />

                    <p class="edition-stats__note">
                        *В календаре выберите необходимый диапазон дат и скачайте статистику в виде <b>exel</b>-файла.
                    </p>
                </div>

                <div class="edition-stats__footer modal-footer">
                    <button
                        class="module-set__save-btn v-btn"
                        type="button"
                        :title="!date ? `Чтобы скачать, выберите в календаре диапазон дат.` : `Нажмите, чтобы скачать excel-файл.`"
                        :disabled="!date"
                        @click="onSave"
                    >
                        Скачать
                    </button>

                    <button
                        class="module-set__cansel-btn v-btn v-btn--transparent"
                        type="reset"
                        @click="$modal.hide('edition-stats')"
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session'

    export default {
        name: "MaterilEditionStatistics",

        data() {
            return {
                date: null,
                maxDate: null
            }
        },

        mounted() {
            const date = new Date();
            this.maxDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },

        methods: {
            async onSave() {
                await session.get(
                    `api/v1/material/report/?date_from=${this.date.start}&date_to=${this.date.end}`
                )
                    .then((res) => {
                        const request = res.request;
                        const url = request.responseURL;
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute('download', `edit_report_${this.date.start}_${this.date.end}.xlsx`);

                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'Во время скачивания excel-файла произошла ошибка. Давайте попробуем ещё раз.',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400
                        });
                    });
            }
        }
    }
</script>

<style lang="scss">
    @import '@/sass/_variables.sass';

    .edition-stats {
        &__note {
            color: #979797;
            font-size: 14px;
        }

        &__footer {
            margin: 20px 0 0;
            justify-content: center;
        }

        p {
            line-height: normal;
        }

        .modal {
            &-header {
                padding-top: 30px;
            }

            &-dialog {
                margin: 0;
            }

            &-footer {
                padding: 20px;
            }
        }

        .block {
            &-content {
                padding: 0 20px 1px;
            }
        }

        .vd {
            &-wrapper {
                width: 100%;
            }

            &-picker {
                width: 100%;
            }
        }
    }
</style>
